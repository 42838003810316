import ApiService from "../../../services/api.service";

export default {
  createFeed({ commit, state }, payload) {
    // Destructure 'state' from context
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    return new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.post(`discover/post/create/`, payload)
        .then((response) => {
          if (response.status === 201) {
            // Assuming response.data contains the new feed item
            commit("SET_FEEDS", [...state.feeds]);
            resolve(response);
          }
        })
        .catch((err) => {
          commit("SET_ERROR", err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    });
  },
  uploadFeedPhotoMedia(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("media-upload/create/", payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteFeedPhotoMedia(_, mediaId) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`media-upload/${mediaId}/delete/`)
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchFeedList({ commit }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    return new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.get("discover/post/list/?ordering=-created_at")
        .then((response) => {
          if (response.status === 200) {
            commit("SET_FEEDS", response.data.results);
            resolve();
          } else {
            reject(new Error("Failed to fetch feed list"));
          }
        })
        .catch((err) => {
          commit("SET_ERROR", err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    });
  },
  async deleteFeed(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.delete(`discover/post/${payload}/delete/`, payload)
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getFollowed(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`user/following-users/?user_id=${payload.id}&relation=${payload.relation}`)
        .then((response) => {
          if (response.status === 200) {
            if(payload.relation==='followers'){
              context.commit("setFollowed", response.data.results);
            }else{
              context.commit("SET_ALL_CONTRIBUTORS", response.data.results);
            }
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getFollowedOrgs(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `organization/following-organizations/?user_id=${payload.id}`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_FOLLOWED_ORGS", response.data.results);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postFeedReaction(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/post/${payload.post_id}/post-reaction/create/`,{reaction:payload.reaction}
      )
        .then((response) => {
          if (response.status === 201) {
            context.commit("feedReaction", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteFeedReaction(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/post/${payload.post_id}/post-reaction/delete/`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("feedReaction", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addContributor(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        'user/multiple-follow/',payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
