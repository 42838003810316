export default {
  setProfileInfo(state, payload) {
    state.topics = payload.profile.topics;
    let personalInfo = {
      email: payload.email,
      full_name: payload.profile.full_name,
      profile_picture: payload.profile.profile_picture,
      phone_no: payload.profile.phone_no,
    };
    state.personalInfo = personalInfo;
    state.interests = payload.profile.interests;
  },
  setCertificates(state, payload) {
    let certificates = [];
    payload.map((val) => {
      certificates.push({
        id: val.id,
        title: val.title,
        issuing_organization: val.issuing_organization,
        issue_date: val.issue_date,
        expire_date: val.expire_date,
        does_not_expire: val.does_not_expire,
        credential_id: val.credential_id,
        credential_url: val.credential_url,
      });
    });
    state.certificates = certificates;
  },
  addCertificate(state, payload) {
    state.certificates.unshift(payload);
  },
  updateCertificate(state, payload) {
    let certificateIndex = state.certificates.findIndex(
      (obj) => obj.id == payload.id
    );
    state.certificates[certificateIndex] = payload;
  },
  deleteCertificate(state, payload) {
    let certificates = state.certificates.filter((val) => {
      return val.id !== payload;
    });
    state.certificates = certificates;
  },
  setExperienceLoader(state, payload) {
    state.experienceSkeletonLoader = payload;
  },
  setExperiences(state, payload) {
    let experiences = [];
    payload.map((val) => {
      experiences.push({
        id: val.id,
        title: val.title,
        organization: val.organization,
        description: val.description,
        experience_type: val.experience_type,
        start_date: val.start_date,
        end_date: val.end_date,
        present: val.present,
        duration: val.duration,
      });
    });
    state.experiences = experiences;
  },
  addExperience(state, payload) {
    state.experiences.unshift(payload);
  },
  updateExperience(state, payload) {
    let experienceIndex = state.experiences.findIndex(
      (obj) => obj.id == payload.id
    );
    state.experiences[experienceIndex] = payload;
  },
  deleteExperience(state, payload) {
    let experiences = state.experiences.filter((val) => {
      return val.id !== payload;
    });
    state.experiences = experiences;
  },
  setAudienceGroups(state, payload) {
    state.audienceGroups = payload;
  },
  addNewAudienceGroup(state, payload) {
    state.audienceGroups.push(payload);
  },
  updateAudienceGroup(state, payload) {
    let groupIndex = state.audienceGroups.findIndex(
      (obj) => obj.id == payload.id
    );
    state.audienceGroups[groupIndex] = payload;
  },
  deleteAudienceGroup(state, payload) {
    let groups = state.audienceGroups.filter((val) => {
      return val.id !== payload;
    });
    state.audienceGroups = groups;
  },
  addTopic(state, payload) {
    state.topics.unshift(payload);
  },
  updateTopic(state, payload) {
    let topicIndex = state.topics.findIndex((obj) => obj.id == payload.id);
    state.topics[topicIndex] = payload;
  },
  deleteTopic(state, payload) {
    let topics = state.topics.filter((val) => {
      return val.id !== payload;
    });
    state.topics = topics;
  },
  GET_FRIENDS_LIST(state, payload) {
    state.friendsCount = payload.friends_count;
    state.friends = payload.friends;
  },
  setConectionLoader(state, payload) {
    state.isConnectionsLoading = payload;
  },
  addAudienceToGroup(state, payload) {
    const friendsInGroup = state.friends
      .filter(friend => payload.user.includes(friend.id))
      .map(friend => ({
        id: friend.id,
        email: friend.email,
        full_name: friend.profile.full_name,
        profile_picture: friend.profile.profile_picture,
        organization: friend.profile.company_name,
        job_title: friend.profile.job_title,
        mutual_friends: friend?.mutual_friends?.body?.message,
        mutual_friends_image: friend?.mutual_friends?.body?.user?.profile_picture,
      }));
    state.connectionsInGroup[payload.group] = friendsInGroup;
    state.group = payload;
  },  
  // addAudienceToGroup(state, payload) {
  //   let friendsInGroup = [];
  //   console.log('payload',payload);
  //   state.friends.map((friend) => {
  //     payload.user.map((addedFriendId) => {
  //       if (friend.id === addedFriendId) {
  //         let obj = {
  //           id: addedFriendId,
  //           email: friend.email,
  //           full_name: friend.profile.full_name,
  //           profile_picture: friend.profile.profile_picture,
  //           organization: friend.profile.company_name,
  //           job_title: friend.profile.job_title,
  //           mutual_friends: friend.mutual_friends.body.message,
  //           mutual_friends_image: friend.mutual_friends.body.user.profile_picture,
  //         };
  //         friendsInGroup.push(obj);
  //       }
  //     });
  //   });
  //   state.connectionsInGroup[payload.group] = friendsInGroup;
  //   state.group = payload;
  // },
  setGroupLoader(state, payload) {
    state.isGroupLoadings = payload;
  },
  addFriendToGroup(state, payload) {
    state.friends.map((friend) => {
      if (friend.id == payload.users[0]) {
        const obj = {
          id: friend.id,
          email: friend.email,
          full_name: friend.profile.full_name,
          organization: friend.profile.company_name,
          profile_picture: friend.profile.profile_picture,
        };
        if (state.connectionsInGroup[payload.group]) {
          state.connectionsInGroup[payload.group].push(obj);
        } else {
          state.connectionsInGroup[payload.group] = [obj];
        }
      }
    });
  },
  setLanguages(state, payload) {
    state.languages = payload;
  },
  setLanguagesLoader(state, payload) {
    state.languagesSkeletonLoader = payload;
  },
  addLanguage(state, payload) {
    state.languages.push(payload);
  },
  updateLanguage(state, payload) {
    let languageIndex = state.languages.findIndex(
      (obj) => obj.id == payload.id
    );
    state.languages[languageIndex] = payload;
  },
  deleteLanguage(state, payload) {
    let languages = state.languages.filter((val) => {
      return val.id !== payload;
    });
    state.languages = languages;
  },
  updateTimeline(state) {
    let timeline = [];
    state.experiences.map((exp) => {
      let experience = {
        id: exp.id,
        title: exp.title,
        year: exp.start_date,
      };
      timeline.push(experience);
    });
    // state.certificates.map((cert) => {
    //   let certificate = {
    //     id: cert.id,
    //     title: cert.title,
    //     year: cert.issue_date.slice(0, 4),
    //   };
    //   timeline.push(certificate);
    // });
    state.timeline = timeline;
  },

  setInterests(state, payload) {
    state.interests = payload;
  },

  // setMission(state, payload){
  //     state.mission = payload;
  // },
  // updateMission(state, payload){
  //     state.mission = payload;
  // },
  // setIntroduction(state, payload){
  //     state.introduction = payload;
  // },
  // updateIntroduction(state, payload){
  //     state.introduction = payload;
  // },
  setProfile(state, payload) {
    state.profile = payload;
  },
  setProfilePicture(state, payload) {
    state.profilePicture = payload;
  },
  setUserProfile(state, payload) {
    state.userProfile = payload;
    state.topics = payload.profile.topics;
  },
  setUserProfileLoader(state, payload) {
    state.profileSkeletonLoader = payload;
  },
  // getUserProfile(state, payload){
  //     state.getUserProfile = payload
  // },

  // setMission(state, payload){
  //     state.mission = payload;
  // },
  // updateMission(state, payload){
  //     state.mission = payload;
  // },
  // setIntroduction(state, payload){
  //     state.introduction = payload;
  // },
  // updateIntroduction(state, payload){
  //     state.introduction = payload;
  // },
  SET_PROFILE_PICTURE(state, payload) {
    state.profilePicture = payload;
  },
  GET_USER_PROFILE(state, payload) {
    state.getUserProfile = payload;
  },

  REMOVE_USER(state, payload) {
    state.friends = state.friends.filter((friend) => friend.id !== payload);
  },
  REMOVE_GROUP_USER(state, payload) {
    state.connectionsInGroup = state.friends.filter(
      (user) => user.id !== payload
    );
  },
  setConnectionRequest(state, payload) {
    state.connectionStatus = payload;
  },
  setUserRequests(state, payload) {
    state.userRequests = payload;
  },
  setRequestCount(state, payload) {
    state.requestCount = payload;
  },
  setNotificationCount(state, payload) {
    state.notificationCount = payload.total_unread;
  },
  setNotifications(state, payload) {
    state.totalNotifications = payload;
  },
  setLoggedInUserDetails(state, payload) {
    state.loggedInUserDetails = payload;
  },
  setUsersCategories(state, payload) {
    state.userCategories = payload;
  },
  setSubscriptionModalVisisblity(state, payload) {
    state.visibleSubscriptionModal = payload;
  },
  setPaidField(state, payload) {
    state.loggedInUserDetails.is_paid = payload;
  },
  setPhotoResume(state, payload) {
    state.photoResume = payload;
  },
  setPhotoResumeLoader(state, payload) {
    state.photoResumeSkeletonLoader = payload;
  },
  setPhotoResumeAfterUpload(state, payload) {
    payload.map((element) => {
      state.photoResume.push(element);
    });
  },
  updatePhotoResume(state, payload) {
    state.photoResume = state.photoResume.filter((val) => {
      return val.id !== payload;
    });
  },
  setSavedFolderComments(state, payload) {
    return (state.savedFoldersComments = payload);
  },
  setContacts(state, payload) {
    state.connections = payload;
  },
  setAccountType(state, payload) {
    state.accountType = payload;
  },
  setEvents(state, payload) {
    if(payload.results){
      state.events = payload.results;
      state.eventsNext = payload.next;
      state.eventsPrevious = payload.previous;
      state.eventsCount = payload.count; 
    }
  },
  setEventsSkeletonLoader(state, payload) {
    state.eventsSkeletonLoader = payload.results;
  },
  setSelectedEvent(state, event) {
    state.selectedEvent = event;
  },
  resetSelectedEvent(state) {
    state.selectedEvent = null;
  },
};
