import ApiService from "../../../services/api.service";
import Cookies from "js-cookie";

export default {
  connectDiscussionSocket(context, payload) {
    const url = `${process.env.VUE_APP_ROOT_WEBSOCKET}pdb-discussion/${payload.interest_group}/?token=${Cookies.get(
      "access_token"
    )}`

    let connection = context.state.discussionSocketConnection;

    if (connection) {
      connection.close();
      connection = null;
    }

    connection = new WebSocket(url);

    connection.onopen = () => {
      console.log("Connected");
    };

    connection.onmessage = (event) => {
      const discussions = JSON.parse(event.data);
      if (payload.isPrivate) {
        const newPrivateDiscussion =
          context.getters.getPrivateDiscussionComments;
        newPrivateDiscussion.push(discussions.data);
        context.commit("SET_PRIVATE_DISCUSSION_COMMENTS", newPrivateDiscussion);
      } else if (!payload.isPrivate) {
        const newPublicDiscussion = context.getters.getPublicDiscussionComments;
        newPublicDiscussion.push(discussions.data);
        context.commit("SET_PUBLIC_DISCUSSION_COMMENTS", newPublicDiscussion);
      }
    };

    connection.onclose = () => {
      console.log("Connection closed");
    };
  },

  // Comments Of Discussion Section

  createDiscussionComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/create/`,
        payload
      )
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchDiscussionComments(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_DISCUSSION_COMMENTS", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setDiscussionComments(context, payload) {
    return new Promise((resolve, reject) => {
      let requestUrl = `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/?ordering=-created_at&page=${payload.page}&page_size=${payload.page_size}`;
      if( payload.filterOptions && payload.filterOptions.user ){
        requestUrl = requestUrl + `?multiple_users=${payload.filterOptions.user}`
        if(payload.filterOptions.startDate){
          requestUrl =
            requestUrl +
            `&data_range_after=${payload.filterOptions.startDate}&data_range_before=${payload.filterOptions.endDate}`;
        }
      }else if(payload.filterOptions && payload.filterOptions.startDate){
        requestUrl =
          requestUrl +
          `?data_range_after=${payload.filterOptions.startDate}&data_range_before=${payload.filterOptions.endDate}`;
       }
      ApiService.get(requestUrl)
        .then((response) => {
          if (response.status === 200) {
            context.commit(
              `SET_${payload.group}_DISCUSSION_COMMENTS`,
              response.data.results.reverse()
            );
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // setPublicDiscussionComments(context, payload) {
  //   return new Promise((resolve, reject) => {
  //     let requestUrl = `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/`;
  //     ApiService.get(requestUrl)
  //       .then((response) => {
  //         let oldDiscussion = context.getters.getPublicDiscussionComments.reverse();
  //         if (response.status === 200) {
  //           context.commit(
  //             `SET_${payload.group}_DISCUSSION_COMMENTS`,
  //             response.data.results
  //           );
  //           resolve(response);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },

  updateDiscussionCommentst({commit}, payload) {
    const group = payload.group
    delete payload.group
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/${payload.discussion_id}/update/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit(`EDIT_COMMENT_${group}_DISCUSSION`,response.data)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteDiscussionComments({commit}, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/group/${payload.interest_group_id}/discussion/${payload.interest_id}/comment/${payload.discussion_id}/destroy/`
      )
        .then((response) => {
          if (response.status === 204) {
            commit(`DELETE_COMMENT_${payload.group}_DISCUSSION`,payload)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reportaDiscussion(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`workspace/${payload.instance_id}/report/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Message section Api's

  connectChattingSocket(context, payload) {
    const url = `${process.env.VUE_APP_ROOT_WEBSOCKET}pdb-chatting/${payload.interest_group}/?token=${Cookies.get(
      "access_token"
    )}`;
    
    let connection = context.state.ChattingSocketConnection;

    if (connection) {
      connection.close();
      connection = null;
    }

    connection = new WebSocket(url);

    connection.onopen = () => {
      console.log("Connected");
    };

    connection.onmessage = (event) => {
      const discussions = JSON.parse(event.data);
      let oldChats = context.getters.getTopicChats.reverse();
      oldChats.push(discussions.data);
      context.commit("SET_TOPICS_CHATS", oldChats.reverse());
    };

    connection.onclose = () => {
      console.log("Connection closed");
    };
  },

  createChattingMessage(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/pdb-chatting/interest/${payload.interest_id}/chat/create/`,
        payload
      )
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchChattingMesssage(context, payload) {
    if (context.getters.getNextUrl) {
      return new Promise((resolve, reject) => {
      let requestUrl =`discover/pdb-chatting/interest/${payload.interest_id}/chat/list/?ordering=-created_at&page=${context.getters.getNextUrl}&page_size=${payload.page_size}`;
      ApiService.get(requestUrl)
        .then((response) => {
          let oldChats = context.getters.getTopicChats.reverse();
          if (response.status === 200) {
            context.commit("SET_NEXT_URL", response.data.next);
            if (oldChats) {
              let newChats = oldChats.concat(
                response.data.results
              );
              context.commit(
                "SET_TOPICS_CHATS",
                newChats.reverse()
              );
            } else {
              context.commit(
                "SET_TOPICS_CHATS",
                response.data.results.reverse()
              );
            }
            context.commit("SET_CHATS_LOADER", false);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    }
  },

  updateChattingMessage(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/pdb-chatting/interest/${payload.interest_id}/chat/${payload.chat_id}/update/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteChattingMessage(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/pdb-chatting/interest/${payload.interest_id}/chat/${payload.chat_id}/delete/`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reportChattingMessage(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`workspace/${payload.instance_id}/report/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCommentList({commit},commentDetails){
    const {group, comment} = commentDetails
    commit(`UPDATE_${group}`,comment)
  },
  fetchDiscussionUsers({commit},{interest_group_id, interest_id, group }){
    return new Promise((resolve, reject) => {   
    ApiService.get(
          `discover/group/${interest_group_id}/discussion/${interest_id}/comment/users/`
      )
        .then((response) => {
          if (response.status === 200) {
            commit(`SET_${group}_DISUCSSION_USERS`, response.data)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // fetchPublicDiscussionUsers(_,{interest_group_id, interest_id}){
  //   return new Promise((resolve, reject) => {   
  //   ApiService.get(
  //       `discover/group/${interest_group_id}/discussion/${interest_id}/comment/users`
  //     )
  //       .then((response) => {
  //         if (response.status === 200) {
  //           console.log("RESPONSE", response)
  //           resolve(response);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
  filterComments({commit}, filterOption){
    let apiUrl = `discover/group/${filterOption.interestGroupId}/?data_range_after=${filterOption.endDate}&data_range_before=${filterOption.startDate}`
    if(filterOption.user)
        apiUrl = apiUrl + `&multiple_users=${filterOption.user}`
      return new Promise((resolve, reject) => {   
    ApiService.get(
      apiUrl
      )
        .then((response) => {
          if (response.status === 200) {
            commit(`SET_${filterOption.group}_DISCUSSION_COMMENTS`,response.data)
            console.log("RESPONSE", response.data)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    
},

likeComment(_,commentDetails){
  const apiUrl = `discover/group/${commentDetails.interestGroupId}/discussion/${commentDetails.interestId}/comment/${commentDetails.commentId}/action/create/`
  return new Promise((resolve, reject) => {   
  ApiService.post(
    apiUrl,
    commentDetails.action
    )
      .then((response) => {
        if (response.status === 201) {
          // commit(`SET_${filterOption.group}_DISCUSSION_COMMENTS`,response.data)
          // console.log("RESPONSE", response.data)
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  })
},

deleteLikeDislike(_,commentDetails){
  const apiUrl = `discover/group/${commentDetails.interestGroupId}/discussion/${commentDetails.interestId}/comment/${commentDetails.commentId}/action/delete/`
  return new Promise((resolve, reject) => {   
  ApiService.delete(
    apiUrl
    )
      .then((response) => {
        if (response.status === 204) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  })
},

toggleDiscoverTopicCommentLoader({commit,state}){
  commit('TOGGLE_DICOVER_TOPIC_DISCUSSION_LOADER',!state.discoverTopicDiscussionLoader)
},
setSelectedDiscussionArticleId: ({commit}, articleId)=>{ commit('SET_SELECTED_DISCUSSION_ARTICLE_ID', articleId) }
};
